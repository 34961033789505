<template>
  <b-sidebar
    id="add-new-user-sidebar"
    :visible="isSidebarActive"
    bg-variant="white"
    sidebar-class="sidebar-lg"
    shadow
    backdrop
    no-header
    right
    @change="(val) => $emit('update:is-sidebar-active', val)"
  >
    <template #default="{ hide }">
      <!-- Header -->
      <div class="d-flex justify-content-between align-items-center content-sidebar-header px-2 py-1">
        <h5 class="mb-0">
          {{ Object.entries(data).length === 0 ? $t("Add New") : $t("Update") }}
        </h5>

        <feather-icon
          class="ml-1 cursor-pointer"
          icon="XIcon"
          size="16"
          @click="hide"
        />
      </div>
      <!-- Form -->
      <validation-observer ref="formData">
        <b-form class="p-2">
          <b-row>
            <b-col cols="12">
              <b-form-group
                :label="$t('Image')"
                label-for="register-image"
              >
                <!-- media -->
                <b-media no-body>
                  <b-media-aside>
                    <b-link>
                      <b-img
                        v-if="image"
                        ref="previewEl"
                        rounded
                        :src="image"
                        height="80"
                      />
                      <b-img
                        v-else
                        ref="previewEl"
                        :src="require('@/assets/images/blank/no_image.png')"
                        rounded
                        height="80"
                      />
                    </b-link>
                    <!--/ avatar -->
                  </b-media-aside>

                  <b-media-body class="mt-75 ml-75">
                    <!-- upload button -->
                    <b-button
                      v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                      variant="primary"
                      size="sm"
                      class="mb-75 mr-75"
                      @click="$refs.refInputEl.$el.click()"
                    >
                      {{ $t("Upload") }}
                    </b-button>
                    <b-form-file
                      ref="refInputEl"
                      accept=".webp, .jpeg, .jpg, .png, .gif"
                      :hidden="true"
                      plain
                      @change="inputImageRenderer"
                    />
                    <!--/ upload button -->
                    <!-- reset -->
                    <b-button
                      v-ripple.400="'rgba(186, 191, 199, 0.15)'"
                      variant="outline-secondary"
                      size="sm"
                      class="mb-75 mr-75"
                      @click="resetImage"
                    >
                      {{ $t("Reset") }}
                    </b-button>
                    <!--/ reset -->
                    <b-card-text>{{ $t('Allowed') }} JPG, GIF {{ $t('or') }} PNG. {{ $t('Max size of') }} 300KB</b-card-text>
                  </b-media-body>
                </b-media>
                <!--/ media -->
              </b-form-group>
            </b-col>
            <b-col cols="12">
              <b-form-group
                :label="$t('Type')"
                label-for="register-type"
              >
                <validation-provider
                  #default="{ errors }"
                  name="type"
                  vid="type"
                  rules="required"
                >
                  <v-select
                    id="register-type"
                    v-model="type"
                    :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                    label="name"
                    :options="dataType"
                    :reduce="(dataType) => dataType.value"
                    class="select-size-lg"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider></b-form-group>
            </b-col>
            <b-col cols="12">
              <b-form-group
                :label="$t('groupToSee')"
                label-for="register-groupToSee"
              >
                <validation-provider
                  #default="{ errors }"
                  name="groupToSee"
                  vid="groupToSee"
                  rules="required"
                >
                  <v-select
                    id="register-groupToSee"
                    v-model="groupToSee"
                    :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                    label="name"
                    :options="OptionGroupToSee"
                    :reduce="(OptionGroupToSee) => OptionGroupToSee.value"
                    class="select-size-lg"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider></b-form-group>
            </b-col>
            <b-col cols="12">
              <b-form-group
                :label="$t('Time')"
                label-for="register-time"
              >
                <b-row>
                  <b-col cols="6">
                    <validation-provider
                      #default="{ errors }"
                      name="time_hour"
                      vid="time_hour"
                      rules="required"
                    >
                      <v-select
                        id="register-time_hour"
                        v-model="hour"
                        :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                        label="name"
                        :options="dataHour"
                        :reduce="(dataHour) => dataHour"
                        class="select-size-lg"
                      />
                      <small class="text-danger">{{ errors[0] }}</small>
                    </validation-provider>
                  </b-col>
                  <b-col cols="6">
                    <validation-provider
                      #default="{ errors }"
                      name="time_minute"
                      vid="time_minute"
                      rules="required"
                    >
                      <v-select
                        id="register-time_minute"
                        v-model="minute"
                        :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                        label="name"
                        :options="dataMinute"
                        :reduce="(dataMinute) => dataMinute"
                        class="select-size-lg"
                      />
                      <small class="text-danger">{{ errors[0] }}</small>
                    </validation-provider>'
                  </b-col>
                </b-row>
              </b-form-group>
            </b-col>
            <b-col cols="12">
              <b-form-group
                :label="$t('Action Click')"
                label-for="register-actionClick"
              >
                <validation-provider
                  #default="{ errors }"
                  name="actionClick"
                  vid="actionClick"
                  rules="required"
                >
                  <v-select
                    id="register-actionClick"
                    v-model="actionClick"
                    :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                    label="name"
                    :options="OptionActionClick"
                    :reduce="(OptionActionClick) => OptionActionClick.value"
                    :clearable="false"
                    class="select-size-lg"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider></b-form-group>
            </b-col>
            <b-col cols="12">
              <b-form-group
                :label="$t('Topic')"
                label-for="register-topic"
              >
                <validation-provider
                  #default="{ errors }"
                  name="Topic"
                  vid="topic"
                  rules="required"
                >
                  <b-form-input
                    id="register-topic"
                    v-model="topic"
                    name="register-topic"
                    :state="errors.length > 0 ? false : null"
                    :placeholder="$t('Topic')"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>
            <b-col cols="12">
              <b-form-group
                :label="$t('Detail')"
                label-for="register-detail"
              >
                <validation-provider
                  #default="{ errors }"
                  name="Detail"
                  vid="detail"
                  rules="required"
                >
                  <b-form-input
                    id="register-detail"
                    v-model="detail"
                    name="register-detail"
                    :state="errors.length > 0 ? false : null"
                    :placeholder="$t('Detail')"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>
            <b-col cols="12">
              <b-form-group
                :label="$t('URL')"
                label-for="register-url"
              >
                <validation-provider
                  #default="{ errors }"
                  name="URL"
                  vid="url"
                  rules="required"
                >
                  <b-form-input
                    id="register-url"
                    v-model="url"
                    name="register-url"
                    :state="errors.length > 0 ? false : null"
                    :placeholder="$t('URL')"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>
          </b-row>
          <!-- Action button -->
          <div class="d-flex mt-2">
            <b-button
              variant="primary"
              class="mr-2"
              @click="addCalendar()"
            >
              {{ $t('Submit') }}
            </b-button>
            <b-button
              type="button"
              variant="outline-secondary"
              @click="hide"
            >
              {{ $t('Cancel') }}
            </b-button>
          </div>
        </b-form>
      </validation-observer>
    </template>
  </b-sidebar>
</template>

<script>
import {
  ValidationProvider,
  ValidationObserver,
} from 'vee-validate'
import {
  BSidebar,
  BInputGroupAppend,
  BInputGroup,
  BFormInput,
  BRow,
  BCol,
  BForm,
  BFormGroup,
  BMedia,
  BMediaAside,
  BMediaBody,
  BLink,
  BImg,
  BCardText,
  BButton,
  BFormFile,
} from 'bootstrap-vue'
import { required } from '@validations'
import { togglePasswordVisibility } from '@core/mixins/ui/forms'
import Ripple from 'vue-ripple-directive'
import flatPickr from 'vue-flatpickr-component'
import vSelect from 'vue-select'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import {
  createEventId,
} from './event-utils'

export default {
  components: {
    BSidebar,
    BButton,
    BFormFile,
    BMedia,
    BMediaAside,
    BMediaBody,
    BLink,
    BImg,
    BCardText,
    BForm,
    // eslint-disable-next-line vue/no-unused-components
    BFormInput,
    // eslint-disable-next-line vue/no-unused-components
    flatPickr,
    BFormGroup,
    // eslint-disable-next-line vue/no-unused-components
    BInputGroupAppend,
    // eslint-disable-next-line vue/no-unused-components
    BInputGroup,
    ValidationProvider,
    ValidationObserver,
    BRow,
    BCol,
    // eslint-disable-next-line vue/no-unused-components
    vSelect,
  },
  directives: {
    Ripple,
  },
  mixins: [togglePasswordVisibility],
  model: {
    prop: 'isSidebarActive',
    event: 'update:is-sidebar-active',
  },
  props: {
    storeModuleName: {
      type: String,
      required: true,
    },
    isSidebarActive: {
      type: Boolean,
      required: true,
    },
    data: {
      type: Object,
      default: () => {},
    },
    show: {
      type: Boolean,
      required: false,
    },
    selectInfo: {
      type: Object,
      default: () => {},
    },
  },
  data() {
    return {
      dataId: null,
      image: '',
      topic: '',
      detail: '',
      type: '',
      profilePartnerId: '',
      hour: '00',
      minute: '00',
      all_day: true,
      every_min: 0,
      url: '',
      title: '',
      startDate: new Date(Date.now()),
      endDate: new Date(Date.now()),
      day: 0,
      time: {
        hour: 0,
        minute: 0,
      },
      receiver: 'guest',
      expired: new Date(Date.now()),
      dataHour: [
        '00',
        '01',
        '02',
        '03',
        '04',
        '05',
        '06',
        '07',
        '08',
        '09',
        '10',
        '11',
        '12',
        '13',
        '14',
        '15',
        '16',
        '17',
        '18',
        '19',
        '20',
        '21',
        '22',
        '23',
      ],
      dataMinute: [
        '00',
        '15',
        '30',
        '45',
        '60',
      ],
      groupToSee: '',
      required,
    }
  },
  computed: {
    passwordToggleIcon() {
      return this.passwordFieldType === 'password' ? 'EyeIcon' : 'EyeOffIcon'
    },
    OptionActionClick() {
      return [
        { name: this.$t('open_profile_partner'.toUpperCase()), value: 'open_profile_partner' },
        { name: this.$t('open_html'.toUpperCase()), value: 'open_html' },
        { name: this.$t('open_link'.toUpperCase()), value: 'open_link' },
      ]
    },
    OptionGroupToSee() {
      return [
        { name: 'Guest', value: 'guest' },
        { name: 'Member', value: 'member' },
        { name: 'Profile', value: 'profile' },
      ]
    },
    dataType() {
      return [
        { name: 'From ADS', value: 'from_ads' },
        { name: 'From Coupon', value: 'from_coupon' },
        { name: 'From Promotion', value: 'from_promotion' },
        { name: 'Fill it yourself', value: 'fill_it_yourself' },
      ]
    },
  },
  watch: {
    isSidebarActive(val) {
      console.log(val)
      // if (val) {
      //   if (Object.entries(this.data).length === 0) {
      //     this.$refs.formData.reset()
      //     this.initValues()
      //   } else {
      //     const {
      //       _id, title, body, route, sound, image, product, userId, groupToSee,
      //     } = this.data
      //     this.dataId = _id
      //     this.title = title
      //     this.body = body
      //     this.route = route
      //     this.sound = sound
      //     this.image = image
      //     this.product = product
      //     this.userId = userId
      //     this.groupToSee = groupToSee
      //   }
      // }
    },
  },
  methods: {
    addCalendar() {
      const calendarApi = this.selectInfo.view.calendar
      calendarApi.addEvent({
        id: createEventId(),
        title: this.topic,
        start: this.selectInfo.startStr,
        end: this.selectInfo.endStr,
        allDay: this.selectInfo.allDay,
      })
      this.$emit('update:is-sidebar-active', false)
    //   }
    },
    inputImageRenderer(input) {
      // eslint-disable-next-line prefer-destructuring
      if (input.target.files && input.target.files[0]) {
        if (input.target.files[0].size > 300000) {
          this.$toast({
            component: ToastificationContent,
            position: 'bottom-right',
            props: {
              title: this.$t('Error'),
              icon: 'ErrorIcon',
              variant: 'danger',
              text: `${this.$t('Allowed')} JPG, GIF ${this.$t('or')} PNG. ${this.$t('Max size of')} 300KB`,
            },
          })
          return
        }

        const reader = new FileReader()
        reader.onload = e => {
          this.image = e.target.result
          // const obj = { image: e.target.result }
          // this.$store
          //   .dispatch(`${this.storeModuleName}/uploadImage`, obj)
          //   .then(resp => {
          //     console.log(resp)
          //     this.image = resp.data.data.url
          //   })
          //   .catch(err => {
          //     console.error(err)
          //   })
        }
        reader.readAsDataURL(input.target.files[0])
      }
    },
    resetImage() {
      this.image = ''
    },
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
@import '@core/scss/vue/libs/vue-flatpicker.scss';
#add-new-user-sidebar {
  .vs__dropdown-menu {
    max-height: 200px !important;
  }
}
</style>
