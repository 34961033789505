<template>
  <div v-if="$ability.can('read', this.$route.meta.resource)">
    <sidebar
      :store-module-name="'public'"
      :is-sidebar-active.sync="isSidebarActive"
      :show.sync="show"
      :data="sidebarData"
      :select-info="selectInfo"
    />
    <div class="demo-app-main">

      <FullCalendar
        class="demo-app-calendar"
        :options="calendarOptions"
      >
        <template v-slot:eventContent="arg">
          <b>{{ arg.timeText }}</b>
          <i>{{ arg.event.title }}</i>
        </template>
      </FullCalendar>
    </div>
  </div>
</template>

<script>
import FullCalendar from '@fullcalendar/vue'
import dayGridPlugin from '@fullcalendar/daygrid'
import timeGridPlugin from '@fullcalendar/timegrid'
import interactionPlugin from '@fullcalendar/interaction'
import Sidebar from './DataSidebar_automation.vue'
import {
  INITIAL_EVENTS,
  // createEventId
} from './event-utils'

// eslint-disable-next-line no-unused-vars
const STORE_MODULE_NAME = 'publicRelations'

export default {

  components: {
    FullCalendar, // make the <FullCalendar> tag available
    Sidebar,
  },

  data() {
    return {
      calendarOptions: {
        plugins: [
          dayGridPlugin,
          timeGridPlugin,
          interactionPlugin, // needed for dateClick
        ],
        headerToolbar: {
          left: 'prev,next today',
          center: 'title',
          right: 'dayGridMonth,timeGridWeek,timeGridDay',
        },
        initialView: 'dayGridMonth',
        initialEvents: INITIAL_EVENTS, // alternatively, use the `events` setting to fetch from a feed
        editable: true,
        selectable: true,
        selectMirror: true,
        dayMaxEvents: true,
        weekends: true,
        select: this.handleDateSelect,
        eventClick: this.handleEventClick,
        eventsSet: this.handleEvents,
        selectInfo: '',
        /* you can update a remote database when these fire:
        eventAdd:
        eventChange:
        eventRemove:
        */
      },
      isSidebarActive: false,
      sidebarData: {},
      show: false,
      currentEvents: [],
    }
  },

  methods: {

    handleWeekendsToggle() {
      this.calendarOptions.weekends = !this.calendarOptions.weekends // update a property
    },

    handleDateSelect(selectInfo) {
      console.log(selectInfo)
      this.selectInfo = selectInfo
      this.openSidebar()
      // this.$bvModal
      //   .msgBoxConfirm(`${this.$t('Do you want to send')}?`, {
      //     title: this.$t('Please Confirm'),
      //     cancelVariant: 'outline-secondary',
      //     okVariant: 'success',
      //     okTitle: this.$t('Yes'),
      //     cancelTitle: this.$t('No'),
      //     centered: true,
      //   })
      // openSidebar()
      // eslint-disable-next-line no-alert
      // const title = prompt('Please enter a new title for your event')
      // const calendarApi = selectInfo.view.calendar

      // calendarApi.unselect() // clear date selection
      // console.log(title)

      // if (title) {
      //   calendarApi.addEvent({
      //     id: createEventId(),
      //     title,
      //     start: selectInfo.startStr,
      //     end: selectInfo.endStr,
      //     allDay: selectInfo.allDay,
      //   })
      // }
    },
    openSidebar() {
      console.log('openSidebar')
      this.sidebarData = {}
      this.isSidebarActive = true
    },
    handleEventClick(clickInfo) {
      // eslint-disable-next-line no-restricted-globals, no-alert
      if (confirm(`Are you sure you want to delete the event '${clickInfo.event.title}'`)) {
        clickInfo.event.remove()
      }
    },

    handleEvents(events) {
      this.currentEvents = events
    },
  },
}
</script>

<style lang='css'>

</style>
